'use strict';

angular.module('enervexSalesappApp').controller('DiscussionsCtrl', function($scope, $stateParams, DiscussionsService, User, Job, Account, Util) {
	$scope.jobLabel = Util.jobLabel;
	$scope.accountId = $stateParams.accountId;
	$scope.jobId = $stateParams.jobId;
	$scope.account = Account.get({
		id: $stateParams.accountId
	});
	$scope.job = Job.get({
		id: $stateParams.jobId,
		accountId: $stateParams.accountId
	});
	fetchDiscussions();
	function fetchDiscussions(){
		DiscussionsService.getDiscussions().$promise.then(function(discussions){
			$scope.discussions = discussions;
		});
	}
	$scope.options = [
		{
			title: 'newest',
			value: '-createdAt'
		},
		{
			title: 'oldest',
			value: 'createdAt'
		},
		{
			title: 'user',
			value: '-createdBy'
		}
	];
	$scope.selectedOrder = $scope.options[0];
	$scope.changeFilter = function(selectedOrder){
		$scope.selectedOrder = selectedOrder;
	}
});
